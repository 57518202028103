import React from 'react';
import ProductPageTemplate from '../../components/ProductPageTemplate'

import rc_schiebetuer_00 from '../../images/rc2_schiebetuer_00_1600.webp'
import rc_schiebetuer_01 from '../../images/rc2_schiebetuer_01_1600.webp'
import rc_schiebetuer_02 from '../../images/rc2_schiebetuer_02_1600.webp'
import rc_schiebetuer_03 from '../../images/rc2_schiebetuer_03_1600.webp'
import rc_schiebetuer_thumb_00 from '../../images/rc2_schiebetuer_00_400.webp'
import rc_schiebetuer_thumb_01 from '../../images/rc2_schiebetuer_01_400.webp'
import rc_schiebetuer_thumb_02 from '../../images/rc2_schiebetuer_02_400.webp'
import rc_schiebetuer_thumb_03 from '../../images/rc2_schiebetuer_03_400.webp'

const context = {
  title: 'Einbruchhemmende Automatik&#173;schiebetür',
  description: 'Höchste Sicherheit für Ihren Gebäudeschutz bei voller Barrierefreiheit',
  text: 'Eine einbruchshemmende Automatikschiebetür besteht, genau wie die automatische Schiebetür, aus ein bis zwei sich öffnenden Türflügeln sowie einer Antriebs- und Sensorikeinheit. Sie kann beispielsweise in ein Rahmenelement (Festverglasung) oder in ein Mauerwerk eingefasst werden. Die Einbaumaße dieser Automatiktüre bewegen sich meist in einer lichten Einbaubreite von 800 mm bis 3000 mm. \n\n' +

  'Um die einbruchshemmende Widerstandsklassen RC2N/RC2 zu erreichen, wird bei der einbruchshemmenden Automatikschiebetür verstärktes Glas sowie verdeckt liegende Mehrpunktverriegelungen und ein Hebeschutz verbaut. So bietet Ihre automatische Schiebetür mehr Sicherheit für Ihr Gebäude und behält gleichzeitig ihr schlankes Design. \n\n' +

  'Nicht von der Stange -  jede Automatiktür ist eine individuell angepasste Lösung. Denn erst wenn das Produkt und die örtlichen Gegebenheiten ineinandergreifen kann eine langfristig zufriedenstellende Eingangssituation entstehen. Um diese nach Ihren Vorstellungen und Wünschen technisch und optisch perfekt zu gestalten, legen wir von Automatiktür24 größten Wert auf eine smarte Beratung und kompetente Umsetzung.',
  features: [
    //'autschr',
    'barrierefreiheit',
    //'brandschutz',
    //'breakOut',
    //'design',
    //'din18650',
    //'energieeinsparung',
    //'modernisierung',
    'notStrom',
    'personenfrequenz',
    //'platzsparend',
    //'transparenz',
    'einbruchhemmung',
    'zutrittskontrolle',
    'fluchtweg',
  ],
  legalRequirements: [
    'autschr',
    'din18650',
    //'dinEn170',
    //'eltvtr',
  ],
  carouselImages: [
    {
      original: rc_schiebetuer_00,
      thumbnail: rc_schiebetuer_thumb_00,
      originalAlt: 'Einbruchhemmende Automatiktür'
    },
    {
      original: rc_schiebetuer_01,
      thumbnail: rc_schiebetuer_thumb_01,
      originalAlt: 'Einbruchhemmende Automatiktür'
    },
    {
      original: rc_schiebetuer_02,
      thumbnail: rc_schiebetuer_thumb_02,
      originalAlt: 'Einbruchhemmende Automatiktür'
    },
    {
      original: rc_schiebetuer_03,
      thumbnail: rc_schiebetuer_thumb_03,
      originalAlt: 'Einbruchhemmende Automatiktür'
    },
  ],
  configurator: true,
}

const Page = () => {

  return(
    <ProductPageTemplate pageContext={context} />
  )
}

export default Page;
